<template>
  <article>
    <h1>문의사항</h1>
    <section class="sub4">
      <div class="sub4_box1">
        <div class="member_txt">
          <h3>MEMBERS</h3>
          <p>아래에서 문의 담당자를 선택해주시오</p>
        </div>
        <div class="member_slider">
          <i class="fas fa-caret-left left"></i>
          <div class="slider_box">
            <ul>
              <li class="on">
                <img src="/img/emoji1.png" alt="">
                <p>김 영태</p>
                <p>CEO & ARCHITECT</p>
              </li>
              <li>
                <img src="/img/emoji2.png" alt="">
                <p>고 윤녕</p>
                <p>MANAGEMENT DIRECTOR</p>
              </li>
              <li>
                <img src="/img/emoji3.png" alt="">
                <p>조 철희</p>
                <p>DATA ARCHITECT & DEVELOPER</p>
              </li>
              <li>
                <img src="/img/emoji4.png" alt="">
                <p>김 영찬</p>
                <p>APPLICATION ARCHITECT & DEVELOPER</p>
              </li>
              <li>
                <img src="/img/emoji5.png" alt="">
                <p>강 승구</p>
                <p>TACHNICAL ARCHITECT & DEVELOPER</p>
              </li>
              <li>
                <img src="/img/emoji6.png" alt="">
                <p>김 인성</p>
                <p>MOBILE ARCHITECT & DEVELOPER</p>
              </li>
              <li>
                <img src="/img/emoji11.png" alt="">
                <p>서 유정</p>
                <p>WEB DESIGNER & PUBLISHER</p>
              </li>
              <li>
                <img src="/img/emoji7.png" alt="">
                <p>이 주민</p>
                <p>WEB DESIGNER & PUBLISHER</p>
              </li>
              <li>
                <img src="/img/emoji8.png" alt="">
                <p>김 유진</p>
                <p>DEVELOPER</p>
              </li>
              <li>
                <img src="/img/emoji9.png" alt="">
                <p>김 수빈</p>
                <p>DEVELOPER</p>
              </li>
              <li>
                <img src="/img/emoji10.png" alt="">
                <p>박 예림</p>
                <p>DEVELOPER</p>
              </li>
            </ul>
          </div>
          <i class="fas fa-caret-right right"></i>
        </div>
        <div class="slider_btn">
          <p class="on"> </p>
          <p> </p>
        </div>
      </div>
      <div class="sub4_box2">
        <div class="form">
          <h3><i class="far fa-paper-plane"></i><strong>CONTACT</strong>FORM</h3>
          <span>" GIS, Infinite Technology to Reality "</span>
          <div class="title_form">
            <h4>Dear -</h4>
            <div class="emoji_bg">
              <div class="bg1 on" data-mail="kyt2808@mapmind.kr">
                <img src="/img/emoji1.png" alt="">
                <p><strong>김 영태</strong></p>
                <p>CEO & ARCHITECT</p>
              </div>
              <div data-mail="mapmind@mapmind.kr">
                <img src="/img/emoji2.png" alt="">
                <p><strong>고 윤녕</strong></p>
                <p>MANAGEMENT DIRECTOR</p>
              </div>
              <div data-mail="kinstole@mapmind.kr">
                <img src="/img/emoji3.png" alt="">
                <p><strong>조 철희</strong></p>
                <p>DATA ARCHITECT & DEVELOPER</p>
              </div>
              <div data-mail="tjam160@mapmind.kr">
                <img src="/img/emoji4.png" alt="">
                <p><strong>김 영찬</strong></p>
                <p>APPLICATION ARCHITECT & DEVELOPER</p>
              </div>
              <div data-mail="sgkang@mapmind.kr">
                <img src="/img/emoji5.png" alt="">
                <p><strong>강 승구</strong></p>
                <p>TACHNICAL ARCHITECT & DEVELOPER</p>
              </div>
              <div data-mail="rladlstjd619@mapmind.kr">
                <img src="/img/emoji6.png" alt="">
                <p><strong>김 인성</strong></p>
                <p>MOBILE ARCHITECT & DEVELOPER</p>
              </div>
              <div data-mail="dbwjd7265@mapmind.kr">
                <img src="/img/emoji11.png" alt="">
                <p><strong>서 유정</strong></p>
                <p>WEB DESIGNER & PUBLISHER</p>
              </div>
              <div data-mail="joos9422@mapmind.kr">
                <img src="/img/emoji7.png" alt="">
                <p><strong>이 주민</strong></p>
                <p>WEB DESIGNER & PUBLISHER</p>
              </div>
              <div data-mail="jjiny@mapmind.kr">
                <img src="/img/emoji8.png" alt="">
                <p><strong>김 유진</strong></p>
                <p>DEVELOPER</p>
              </div>
              <div data-mail="ksb4150@mapmind.kr">
                <img src="/img/emoji9.png" alt="">
                <p><strong>김 수빈</strong></p>
                <p>DEVELOPER</p>
              </div>
              <div data-mail="aerim528@mapmind.kr">
                <img src="/img/emoji10.png" alt="">
                <p><strong>박 예림</strong></p>
                <p>DEVELOPER</p>
              </div>
            </div>
          </div>
        </div>
        <div class="content_form">
          <div class="forma">
            <form onsubmit='return false'>
              <input id="mailSender" type="text" autofocus required placeholder="보내는 사람 (필수입력)" />
              <input id="mailFrom" type="email" autofocus required placeholder="이메일 / 연락처 (필수입력)" />
              <input id="mailCorp" type="text" autofocus placeholder="기업 / 단체명" />
              <input id="mailScope" type="text" autofocus placeholder="문의분야 ( Platform / Dashboard / GIS / Mobile )" />
              <textarea id="mailContents" name="content" cols="30" rows="10" autofocus placeholder="문의내용" style="resize: none;"></textarea>
            </form>
          </div>
          <div class="formb">
            <span id="mailAgree" class=""> </span><p class="click"><strong>개인정보보호정책</strong>에 동의합니다.</p>
            <p v-on:click="sendMail();">Send<i class="far fa-paper-plane"></i></p>
          </div>
        </div>
      </div>
      <div class="individule_info">
        <h3><img src="/img/info.png" alt="">개인정보처리취급방칙</h3>
        <div class="content">
          <h4>개인정보 수집 및 이용에 대한 안내</h4><br>
          <h4>개인정보처리방침</h4><br>
            <p>
              < (주) 맵마인드 >('https://www.mapmind.kr'이하 '(주) 맵마인드')는 「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.<br>
              ○ 이 개인정보처리방침은 2021년 10월 15일부터 적용됩니다.<br><br>
              <strong>제1조(개인정보의 처리 목적)</strong>
              < (주) 맵마인드 >('https://www.mapmind.kr'이하 '(주) 맵마인드')는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.<br>
              1. 홈페이지 회원가입 및 관리<br>
              서비스 부정이용 방지, 각종 고지·통지 목적으로 개인정보를 처리합니다.<br>
              2. 민원사무 처리<br>
              민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 목적으로 개인정보를 처리합니다.<br><br>
              <strong>제2조(개인정보의 처리 및 보유 기간)</strong>
              ① < (주) 맵마인드 >는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.<br>
              ② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.<br>
              1. < 민원사무 처리 ><br>
              <민원사무 처리>와 관련한 개인정보는 수집.이용에 관한 동의일로부터까지 위 이용목적을 위하여 보유.이용됩니다.<br>
              보유근거 : 문의내역 회신<br>
              관련법령 :<br>
              예외사유 :<br><br>
              <strong>제3조(정보주체와 법정대리인의 권리·의무 및 그 행사방법)</strong>
              ① 정보주체는 (주) 맵마인드에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.<br>
              ② 제1항에 따른 권리 행사는 (주) 맵마인드에 대해 「개인정보 보호법」 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 (주) 맵마인드는 이에 대해 지체 없이 조치하겠습니다.<br>
              ③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.<br>
              ④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.<br>
              ⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.<br>
              ⑥ (주) 맵마인드는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.<br><br>
              <strong>제4조(처리하는 개인정보의 항목 작성)</strong> 
              ① < (주) 맵마인드 >는 다음의 개인정보 항목을 처리하고 있습니다.<br>
              1. < 민원사무 처리 ><br>
              필수항목 : 이메일, 이름<br>
              선택항목 : 회사명<br><br>
              <strong>제5조(개인정보의 파기)</strong>
              ① < (주) 맵마인드 >는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.<br>
              ② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.<br>
              1. 법령 근거 :<br>
              2. 보존하는 개인정보 항목 : 계좌정보, 거래날짜<br>
              ③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.<br>
              1. 파기절차<br>
              < (주) 맵마인드 >는 파기 사유가 발생한 개인정보를 선정하고, < (주) 맵마인드 > 의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.<br><br>
              <strong>제6조(개인정보의 안전성 확보 조치)</strong>
              < (주) 맵마인드 >는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.<br>
              1. 정기적인 자체 감사 실시<br>
              개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고 있습니다.<br>
              2. 개인정보 취급 직원의 최소화 및 교육<br>
              개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.<br>
              3. 내부관리계획의 수립 및 시행<br>
              개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.<br>
              4. 해킹 등에 대비한 기술적 대책<br>
              <(주) 맵마인드>('(주) 맵마인드')는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.<br>
              5. 개인정보에 대한 접근 제한<br>
              개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.<br><br>
              <strong>제7조(개인정보 자동 수집 장치의 설치•운영 및 거부에 관한 사항)</strong>
              (주) 맵마인드는 정보주체의 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용하지 않습니다.<br><br>
              <strong>제8조 (개인정보 보호책임자)</strong> 
              ① (주) 맵마인드는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.<br>
              ▶ 개인정보 보호책임자<br>
              성명 :김영태<br>
              직급 :대표이사<br>
              연락처 :070 -7867-2808, mapmind@mapmind.kr<br>
              ② 정보주체께서는 (주) 맵마인드 의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. (주) 맵마인드는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.<br><br>
              <strong>제9조(권익침해 구제방법)</strong>
              정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.<br>
              1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)<br>
              2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)<br>
              3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)<br>
              4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)<br>
              「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.<br>
              ※ 행정심판에 대해 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기 바랍니다.<br><br>
              <strong>제10조(개인정보 처리방침 변경)</strong>
              ① 이 개인정보처리방침은 2021년 10월 15일부터 적용됩니다.<br><br><br>
            </p>
        </div>
        <div class="checkbtn">
          <p>확인 후 동의합니다.</p>
        </div>
      </div>
    </section>
  </article>
</template>
<script>
export default {
  mounted() {
    let mailto = parseInt(this.$route.query.mailto);
    mailto = isNaN(mailto) ? 0 : mailto;
    let members = $(".member_slider ul li");
    members[mailto].click();
    if(mailto > 4){
      for(let i=0 ; i<(mailto - 4) ; i++){
        $(".member_slider .right").click();
      }
    }
  }, 
  methods: {
    sendMail: function(){
      let agree = $("#mailAgree").attr("class");
      if(typeof agree != "undefined" && agree == "on"){
        let mailTo = $(".emoji_bg > .on").attr("data-mail");
        let mailFrom = $("#mailFrom").val();
        let mailSender = $("#mailSender").val();
        let mailCorp = $("#mailCorp").val();
        let mailScope = $("#mailScope").val();
        let mailContents = $("#mailContents").val();
        let blankFlag = false;
        let blankEntrys = "";
        if(mailSender.replace(/ /g,"") == ""){
          if(blankEntrys != "") blankEntrys += ", ";
          blankEntrys += $("#mailSender").attr("placeholder");
          blankFlag = true;
        }
        if(mailFrom.replace(/ /g,"") == ""){
          if(blankEntrys != "") blankEntrys += ", ";
          blankEntrys += $("#mailFrom").attr("placeholder");
          blankFlag = true;
        }

        let invalidMail = false;
        let mailRegExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
        if(!mailRegExp.test(mailFrom)){
          invalidMail = true;
        }

        if(blankFlag){
          alert(blankEntrys + "항목이 누락되었습니다.");
        }else if(invalidMail){
          alert("이메일 주소를 형식에 맞게 작성해 주세요.")
        }else{
          $.ajax({
            url: "https://rest.mapmind.kr/rest/sendMail"
            , method: "POST"
            , data: {
              email: mailTo
              , requestMail: mailFrom
              , contents: `보내는 사람 : ${mailSender} (${mailFrom})
기업/단체명 : ${mailCorp}
문의분야 : ${mailScope}
문의내용
${mailContents}
`
            }
            , dataType: "json"
            , success: function(result){
              if(result.status == true){
                alert("이메일 전송이 완료되었습니다.");
                $("#mailAgree").removeClass("on");
                $("#mailFrom").val("");
                $("#mailSender").val("");
                $("#mailCorp").val("");
                $("#mailScope").val("");
                $("#mailContents").val("");
              }else{
                alert(result.message, "error");
              }
            }
            , error: function(xhr, stat, err){
              console.log(xhr);
              console.log(stat);
              console.log(err);
            }
          });
        }
      }else{
        alert("개인정보보호정책에 동의 후 이용할 수 있습니다.");
      }
    }
  }
}



// 하단 동그라미 하나당 움직을 멤버 상수
const memberSlideStep = 6;

// 현재 처음으로 보이는 멤버의 인덱스
let currentFirstMemeberIdx = 0;

// 멤버를 슬라이드 하는 함수
function slideMember(){
  // 멤버 li를 슬라이드
  $('.member_slider ul li').css('left', (-10 * currentFirstMemeberIdx) + 'vw');
  // 하단 동그라미 on 클래스 핸들링
  // 점 인덱스 계산
  let pointIdx = Math.floor( currentFirstMemeberIdx / memberSlideStep );
  // 하단 모든 동그라미에서 on 클래스 제거
  $(".slider_btn p").removeClass("on");
  // 점 인덱스에 해당하는 하단 동그라미에 on 클래스 추가
  $($(".slider_btn p")[pointIdx]).addClass("on");
}

// 왼쪽 화살표 클릭 핸들러
$(document).on("click", ".member_slider .left", function(){
  // 현재 가장 처음이 아니라면
  if(currentFirstMemeberIdx > 0){
    // 현재 처음으로 보이는 멤버의 인덱스 하나 줄임
    currentFirstMemeberIdx--;
    // 멤버를 슬라이드 하는 함수 호출
    slideMember();
  }
});

// 오른쪽 화살표 클릭 핸들러
$(document).on("click", ".member_slider .right", function(){
  // 멤버의 개수
  let memberLiCount = $(".member_slider ul li").length;

  // 현재 가장 마지막의 바로 전이 아니라면 (가장 마지막은 보여야 하니까)
  if(currentFirstMemeberIdx < memberLiCount - 5){
    // 현재 처음으로 보이는 멤버의 인덱스 하나 늘림
    currentFirstMemeberIdx++;
    // 멤버를 슬라이드 하는 함수 호출
    slideMember();
  }
});

// 하단 동그라미 클릭 핸들러
$(document).on("click", ".slider_btn p", function(){
  // 클릭한 동그라미 인덱스
  let sliceBtnIdx = $(this).index();
  // 현재 처음으로 보이는 멤버의 인덱스에 점 하나당 움직을 멤버 상수 * 클릭한 동그라미 인덱스를 대입
  currentFirstMemeberIdx = memberSlideStep * sliceBtnIdx;
  // 멤버를 슬라이드 하는 함수 호출
  slideMember();
});

// 멤버 li 클릭 핸들러
$(document).on("click", ".member_slider ul li", function(){
  let selectedMemberIdx = $(this).index();
  $('.member_slider ul li').removeClass('on')
  $('.member_slider ul li').eq(selectedMemberIdx).addClass('on')
  $('.title_form .emoji_bg>div').removeClass('on')
  $('.title_form .emoji_bg>div').eq(selectedMemberIdx).addClass('on')
});

//체크박스 클릭시
$(document).on('click','.formb span',function(){
  $('.formb span').addClass('on');
})

//개인정보 누를시 팝업창 활성화
$(document).on('click','.formb .click strong',function(){
  $('.individule_info').addClass('on');
})
//팝업창 확인후 사라지기
$(document).on('click','.individule_info .checkbtn',function(){
  $('.individule_info').removeClass('on');
  $('.formb span').addClass('on');
})












// //슬라이드 이미지hover효과
// $(document).on("click", ".member_slider ul li", function(){
//   var a = $(this).index();
//   console.log(a)
//   $('.member_slider ul li').removeClass('on')
//   $('.member_slider ul li').eq(a).addClass('on')
//   $('.title_form .emoji_bg>div').removeClass('on')
//   $('.title_form .emoji_bg>div').eq(a).addClass('on')
// });
// //슬라이드 왼,오 아이콘 누를시 이동
// var b=0;
// $(document).on("click", ".member_slider .left", function(){
//   if(b>=1) b--;
//   console.log(b);
//     $('.member_slider ul li').css('left', -13*b+'vw')
  
// });
// $(document).on("click", ".member_slider .right", function(){
//   if(b<=2) b++;
//   console.log(b);
//     $('.member_slider ul li').css('left', -13*b+'vw')
  
// });
// //슬라이드 버튼 누를시 이동
// $(document).on("click", ".slider_btn p", function(){
//   var c = $(this).index();
//   console.log(c);
//   $('.slider_btn p').removeClass('on')
//   $('.slider_btn p').eq(c).addClass('on')

//   $('.slider_btn p').eq(1).on('click', function(){
//     $('.member_slider ul li').css('left', -39+'vw')
//   })
//   $('.slider_btn p').eq(0).on('click', function(){
//     $('.member_slider ul li').css('left', 0+'vw')
//   })

// });
// //슬라이드버튼on값 변동
// $(document).on('click','.slider_box ul li',function(){
//   $('.slider_box ul li').eq(0).on('click',function(){
//     $('.slider_btn p').removeClass('on')
//     $('.slider_btn p').eq(0).addClass('on')
//   })
//   $('.slider_box ul li').eq(1).on('click',function(){
//     $('.slider_btn p').removeClass('on')
//     $('.slider_btn p').eq(0).addClass('on')
//   })
//   $('.slider_box ul li').eq(2).on('click',function(){
//     $('.slider_btn p').removeClass('on')
//     $('.slider_btn p').eq(0).addClass('on')
//   })
//   $('.slider_box ul li').eq(3).on('click',function(){
//     $('.slider_btn p').removeClass('on')
//     $('.slider_btn p').eq(0).addClass('on')
//   })
//   $('.slider_box ul li').eq(4).on('click',function(){
//     $('.slider_btn p').removeClass('on')
//     $('.slider_btn p').eq(0).addClass('on')
//   })
//   $('.slider_box ul li').eq(5).on('click',function(){
//     $('.slider_btn p').removeClass('on')
//     $('.slider_btn p').eq(1).addClass('on')
//   })
//   $('.slider_box ul li').eq(6).on('click',function(){
//     $('.slider_btn p').removeClass('on')
//     $('.slider_btn p').eq(1).addClass('on')
//   })
//   $('.slider_box ul li').eq(7).on('click',function(){
//     $('.slider_btn p').removeClass('on')
//     $('.slider_btn p').eq(1).addClass('on')
//   })
// })
</script>
